(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/track-ga-wager/assets/javascripts/track-ga-wager.js') >= 0) return;  svs.modules.push('/components/utils/track-ga-wager/assets/javascripts/track-ga-wager.js');
"use strict";


const {
  transaction,
  transactionItem,
  trackTransaction
} = svs.components.analytics;
function trackGAWager(wager) {
  const items = wager.bets.map(bet => new transactionItem(bet.productId, bet.productName, bet.price, bet.numberOfRows, bet.extraData));
  trackTransaction(new transaction(wager.wagerId, wager.totalPrice, items, 0, 0, undefined, wager.productName));
}
setGlobal('svs.utils.trackGAWager', trackGAWager);

 })(window);